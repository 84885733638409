import React from "react"
// import { Link } from "gatsby"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { OutboundLink } from "gatsby-plugin-google-gtag"

//import icons
import IconRightArrow from "./../../assets/icons/svg/arrow-point-to-right.svg"
import OdownLogo from "./../../assets/logo/logo.svg"
import UptimeRobotLogo from "./../../assets/logo/uptimerobot.com-color-logo.svg"
import AvailableFeatureIcon from "../../assets/icons/svg/tick.svg"
import UnavailableFeatureIcon from "../../assets/icons/svg/cancel.svg"

//import css
import "./../../styles/alternatives-plateforms.css"

//import components
import GetStartedBanner from "../../components/get-started-footer"
import PricingComparator from "./../../components/competitor-price-comparator"
import UpTimeRobotAlternative from "./../../components/UpTimeRobotAlternative"

const Header = ({ data }) => {
  return (
    <section className="alt-header-wrapper">
      <div className="container">
        <div className="alt-header__title">
          <h1>Best Alternative To UptimeRobot</h1>
        </div>
        <div className="alt-header__description">
          {/* <p>If you’re looking for an alternative to UptimeRobot then Odown is the perfect website monitoring service for you.</p> */}
          <p>
            If you're looking for an alternative to UptimeRobot, Odown is the
            perfect website monitoring service for you.
          </p>
        </div>
        <div className="alt-header__button">
          <OutboundLink
            className="btn-primary"
            eventCategory="Launch the Odown App"
            eventAction="Click"
            eventLabel="Goto signup"
            href="https://app.odown.io/signup"
          >
            Start monitoring <IconRightArrow />
          </OutboundLink>
        </div>
      </div>
    </section>
  )
}

const FeaturesComparator = ({ data }) => {
  const featuresDiff = [
    {
      name: "Beautiful dashboard",
      owner: true,
      competitor: false,
      mobile: {
        owner: { sign: true, name: "Beautiful dashboard" },
        competitor: { sign: false, name: "Bad dashboard design" },
      },
    },
    {
      name: "Designed for teams and enterprises ",
      owner: true,
      competitor: false,
      mobile: {
        owner: { sign: true, name: "Designed for teams and enterprises " },
        competitor: {
          sign: false,
          name: "Designed for teams and enterprises ",
        },
      },
    },
    {
      name: "Incident verification & false alert protection",
      owner: true,
      competitor: false,
      mobile: {
        owner: { sign: true, name: "False alert protection" },
        competitor: { sign: false, name: "False alert protection" },
      },
    },
    {
      name: "SMS Alerts",
      owner: "Start at Basic plan (50 SMS )",
      competitor: "Start at Pro plan (20 SMS )",
      mobile: {
        owner: { sign: true, name: "Start with 50 SMS" },
        competitor: { sign: false, name: "Start with 20 SMS" },
      },
    },
    // {
    // 	name : "Integrations" ,
    // 	owner : "Webhook, Slack, SMS" ,
    // 	competitor : "Webhook",
    // mobile : {
    // 		owner : { sign : true , name : "Beautiful dashboard"} ,
    // 		competitor : { sign : false , name : "Bad dashboard design"}
    // 	},
    // },
    {
      name: "Public status pages on your own subdomain",
      owner: true,
      competitor: false,
      mobile: {
        owner: { sign: true, name: "Public Status Page" },
        competitor: { sign: false, name: "Public Status Page" },
      },
    },
    {
      name: "Incident management built-in",
      owner: true,
      competitor: false,
      mobile: {
        owner: { sign: true, name: "Incident management" },
        competitor: { sign: false, name: "Incident management" },
      },
    },
    {
      name: "Team members collaboration",
      owner: true,
      competitor: false,
      mobile: {
        owner: { sign: true, name: "Team members" },
        competitor: { sign: false, name: "Team members" },
      },
    },
    {
      name: "SSL Monitoring alets",
      owner: "Before SSL expires ( Custom days )",
      competitor: "Before SSL expires ( Fixed days )",
      mobile: {
        owner: { sign: true, name: "Alert expired SSL - custom days" },
        competitor: { sign: false, name: "Alert expired SSL- fixed days" },
      },
    },
    {
      name: "Customer service",
      owner: "Fast and available every day",
      competitor: false,
      mobile: {
        owner: { sign: true, name: "Fast customer service" },
        competitor: { sign: false, name: "Slow customer service" },
      },
    },
  ]

  return (
    <section className="features-comparator-table-wrapper">
      <div className="container">
        <h2 className="title">Odown vs UptimeRobot - A Snapshot</h2>
        <div className="content">
          <div className="diff-features">
            <div className="col-side-items">
              <h2>Odown vs. UptimeRobot</h2>
              <ul key={`sidecolumn`}>
                {featuresDiff.map((item, index) => (
                  <li key={index}>{item.name}</li>
                ))}
              </ul>
            </div>
            <div className="col-providers">
              <div className="col-provider owner-service">
                <div className="logo">
                  <OdownLogo />
                </div>
                <ul key={`owner-column`}>
                  {featuresDiff.map((item, index) => (
                    <React.Fragment key={`odown-${index}`}>
                      <li key={`odown-${index}`}>
                        {typeof item.owner === "boolean" ? (
                          item.owner === true ? (
                            <AvailableFeatureIcon className="available-feature" />
                          ) : (
                            <UnavailableFeatureIcon className="unavailable-feature" />
                          )
                        ) : (
                          item.owner
                        )}
                      </li>
                      <li
                        key={`odown-mobile-${index}`}
                        className="v-mob__featureItem"
                      >
                        {typeof item.mobile.owner.sign === "boolean" ? (
                          item.mobile.owner.sign === true ? (
                            <AvailableFeatureIcon className="available-feature" />
                          ) : (
                            <UnavailableFeatureIcon className="unavailable-feature" />
                          )
                        ) : (
                          item.mobile.owner.sign
                        )}
                        <span>{item.mobile.owner.name}</span>
                      </li>
                    </React.Fragment>
                  ))}
                </ul>
              </div>
              <div className="col-provider competitor-service">
                <div className="logo">
                  <UptimeRobotLogo />
                </div>
                <ul key={`competitor-column`}>
                  {featuresDiff.map((item, index) => (
                    <React.Fragment key={`uptimerobot-${index}`}>
                      <li key={`uptimerobot-${index}`}>
                        {typeof item.competitor === "boolean" ? (
                          item.competitor === true ? (
                            <AvailableFeatureIcon className="available-feature" />
                          ) : (
                            <UnavailableFeatureIcon className="unavailable-feature" />
                          )
                        ) : (
                          item.competitor
                        )}
                      </li>
                      <li
                        key={`uptimerobot-mobile-${index}`}
                        className="v-mob__featureItem"
                      >
                        {typeof item.mobile.competitor.sign === "boolean" ? (
                          item.mobile.competitor.sign === true ? (
                            <AvailableFeatureIcon className="available-feature" />
                          ) : (
                            <UnavailableFeatureIcon className="unavailable-feature" />
                          )
                        ) : (
                          item.mobile.competitor.sign
                        )}
                        <span>{item.mobile.competitor.name}</span>
                      </li>
                    </React.Fragment>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

/**
 *
 * @param {*}
 */
const Main = ({ data }) => (
  <Layout>
    <SEO
      title="Best Alternative To Uptime Robot"
      description="Best Uptime robot alternative, a simple and reliable website monitoring service with built-in incident management and beautiful status pages."
      pathname={`/alternatives/uptimerobot`}
    />
    <Header />
    <FeaturesComparator />
    <PricingComparator data={{ selectedUptimeTool: `uptimerobot` }} />
    <UpTimeRobotAlternative />
    {/* <Form />  */}

    {/* Get started banner */}
    <GetStartedBanner />
  </Layout>
)

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
export default Main
